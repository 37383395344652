/* eslint-disable react/prop-types */
import React from 'react';

export default function SvgAdb({ fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 887.000000 273.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,273.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1508 2303 l-216 -125 -156 90 -155 90 -177 -306 c-98 -169 -223
-387 -280 -484 l-102 -177 374 -216 c205 -118 379 -215 385 -215 8 0 10 18 7
57 -5 54 -21 86 -217 423 -165 285 -208 367 -198 376 18 16 936 544 946 544 5
0 57 -83 116 -185 101 -175 109 -185 137 -185 l30 0 -22 38 c-12 20 -70 119
-128 220 -57 100 -110 182 -116 181 -6 0 -108 -57 -228 -126z"
        />
        <path
          d="M300 1380 l0 -610 443 2 442 3 3 22 3 23 -418 2 -418 3 -3 558 -2
557 164 0 c164 0 164 0 180 25 l16 25 -205 0 -205 0 0 -610z"
        />
        <path
          d="M1240 1380 l0 -560 560 0 560 0 0 560 0 560 -560 0 -560 0 0 -560z
m844 28 l204 -428 -82 0 -81 0 -48 95 -47 95 -232 -2 -232 -3 -45 -92 -45 -93
-83 0 -82 0 43 93 c23 50 114 244 201 430 l159 338 83 -3 83 -3 204 -427z"
        />
        <path
          d="M1717 1502 c-42 -93 -77 -172 -77 -175 0 -4 70 -7 155 -7 112 0 155
3 155 12 0 12 -138 318 -149 331 -4 4 -42 -69 -84 -161z"
        />
        <path
          d="M2570 1410 l0 -430 288 0 289 0 43 29 c86 58 145 159 186 319 47 186
19 378 -68 459 -54 50 -74 53 -418 53 l-320 0 0 -430z m603 257 c69 -45 91
-141 63 -276 -27 -130 -78 -214 -149 -240 -16 -6 -99 -11 -188 -11 l-159 0 0
276 0 275 203 -3 c175 -3 207 -5 230 -21z"
        />
        <path
          d="M3730 1409 l0 -430 323 3 c303 3 324 4 357 23 52 31 73 75 78 167 8
139 -25 221 -96 243 -22 6 -21 8 16 27 58 28 72 65 72 185 0 120 -19 171 -73
196 -30 15 -77 17 -356 17 l-321 0 0 -431z m580 271 c34 -34 25 -147 -14 -172
-6 -4 -100 -8 -208 -8 l-198 0 0 100 0 100 200 0 c187 0 201 -1 220 -20z m-2
-346 c19 -13 22 -24 22 -87 0 -40 -4 -78 -8 -85 -16 -24 -78 -32 -254 -32
l-178 0 0 110 0 110 198 0 c163 0 201 -3 220 -16z"
        />
        <path
          d="M5460 1410 l0 -430 80 0 80 0 0 165 0 165 243 1 c270 1 298 6 335 60
22 33 23 40 20 215 -4 267 19 254 -433 254 l-325 0 0 -430z m600 254 c6 -14
10 -57 10 -95 0 -110 3 -109 -245 -109 l-205 0 0 115 0 115 215 0 215 0 10
-26z"
        />
        <path
          d="M7542 1669 c-32 -8 -48 -19 -63 -42 -18 -30 -19 -49 -17 -309 l3
-278 28 -27 27 -28 282 -3 c309 -3 327 -1 356 52 15 27 17 65 17 291 0 280 -3
300 -52 332 -24 16 -55 18 -283 20 -143 1 -274 -3 -298 -8z m498 -339 l0 -220
-225 0 -225 0 0 220 0 220 225 0 225 0 0 -220z"
        />
        <path
          d="M6530 1325 l0 -345 65 0 65 0 0 130 0 130 63 0 63 0 104 -130 105
-129 83 -1 c45 0 82 2 82 4 0 3 -47 60 -104 128 l-104 123 73 3 c68 3 76 5 99
33 25 30 26 34 26 178 0 226 8 221 -360 221 l-260 0 0 -345z m479 211 c8 -9
11 -43 9 -93 l-3 -78 -177 -3 -178 -2 0 95 0 95 169 0 c133 0 171 -3 180 -14z"
        />
        <path d="M4760 1420 l0 -90 195 0 195 0 0 90 0 90 -195 0 -195 0 0 -90z" />
        <path d="M1240 551 l0 -191 40 0 40 0 -2 188 -3 187 -37 3 -38 3 0 -190z" />
        <path
          d="M1487 733 c-4 -3 -7 -89 -7 -190 l0 -183 35 0 34 0 3 130 3 130 118
-130 c100 -111 123 -130 148 -130 l29 0 0 190 0 190 -30 0 -29 0 -3 -132 -3
-131 -120 131 c-94 103 -126 132 -146 132 -14 0 -29 -3 -32 -7z"
        />
        <path
          d="M2070 733 c-36 -13 -40 -34 -40 -205 l0 -168 35 0 35 0 0 70 0 70 65
0 65 0 0 35 0 34 -62 3 -63 3 0 45 0 45 123 3 123 3 -3 32 -3 32 -130 2 c-71
1 -137 -1 -145 -4z"
        />
        <path
          d="M2500 733 c-45 -8 -50 -26 -50 -185 0 -197 -9 -188 188 -188 203 0
192 -11 192 184 0 115 -3 157 -14 171 -13 17 -29 20 -153 21 -76 1 -149 0
-163 -3z m260 -183 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"
        />
        <path
          d="M3010 551 l0 -191 35 0 35 0 0 70 0 70 35 0 c30 0 40 -8 91 -70 55
-68 58 -70 101 -70 52 0 53 -6 -25 85 l-43 50 43 5 c57 7 70 32 66 127 -5 107
-6 107 -188 111 l-150 4 0 -191z m266 94 c3 -14 3 -36 0 -50 -6 -24 -8 -25
-101 -25 l-95 0 0 50 0 50 95 0 c93 0 95 -1 101 -25z"
        />
        <path
          d="M3510 550 l0 -190 35 0 34 0 3 133 3 132 67 -63 66 -63 71 67 71 66
0 -136 0 -136 30 0 30 0 0 190 0 190 -27 0 c-21 0 -46 -18 -100 -72 l-73 -72
-73 72 c-60 59 -78 72 -105 72 l-32 0 0 -190z"
        />
        <path
          d="M4201 718 c-5 -13 -41 -89 -79 -170 -93 -194 -91 -188 -49 -188 30 0
36 5 52 40 l18 40 103 0 104 0 20 -40 c18 -35 25 -40 55 -40 l34 0 -61 128
c-33 70 -73 155 -89 190 -27 59 -30 62 -64 62 -28 0 -37 -5 -44 -22z m109
-195 c0 -9 -20 -13 -66 -13 -49 0 -65 3 -61 13 3 6 17 41 33 76 l28 63 32 -63
c18 -35 33 -70 34 -76z"
        />
        <path
          d="M4517 733 c-4 -3 -7 -19 -7 -35 l0 -28 70 0 70 0 0 -155 0 -155 40 0
40 0 0 155 0 155 65 0 65 0 0 35 0 35 -168 0 c-93 0 -172 -3 -175 -7z"
        />
        <path
          d="M4977 733 c-4 -3 -7 -89 -7 -190 l0 -183 40 0 40 0 0 190 0 190 -33
0 c-19 0 -37 -3 -40 -7z"
        />
        <path
          d="M5220 550 l0 -190 35 0 35 0 0 81 0 82 53 -47 c136 -120 131 -117
186 -114 l51 3 -105 93 c-58 51 -105 97 -105 101 0 4 40 43 89 87 49 43 90 82
90 87 1 4 -19 7 -43 7 -40 0 -49 -5 -130 -81 l-86 -81 0 81 0 81 -35 0 -35 0
0 -190z"
        />
        <path
          d="M5740 563 c-45 -98 -85 -184 -87 -190 -4 -9 6 -13 29 -13 31 0 37 5
53 40 l18 40 103 0 104 0 20 -40 c18 -35 25 -40 55 -40 l35 0 -24 53 c-13 28
-53 114 -89 190 -66 136 -66 137 -100 137 l-34 0 -83 -177z m158 15 l31 -68
-70 0 c-39 0 -69 4 -67 8 34 82 64 140 69 134 3 -4 20 -37 37 -74z"
        />
        <path
          d="M6197 734 c-4 -4 -7 -90 -7 -191 l0 -183 40 0 40 0 -2 188 -3 187
-30 3 c-17 2 -34 0 -38 -4z"
        />
        <path
          d="M6787 734 c-4 -4 -7 -90 -7 -191 l0 -183 40 0 40 0 0 81 0 81 89 -81
90 -82 52 3 53 3 -105 94 c-57 51 -105 96 -106 100 -1 3 42 44 94 91 l95 85
-46 3 c-46 3 -48 2 -129 -73 l-82 -77 -5 74 c-5 73 -5 73 -35 76 -17 2 -34 0
-38 -4z"
        />
        <path
          d="M7307 733 c-30 -8 -37 -47 -37 -214 l0 -159 40 0 40 0 0 70 0 70 60
0 60 0 0 35 0 35 -60 0 -60 0 0 50 0 50 120 0 120 0 0 35 0 35 -132 -1 c-73
-1 -141 -3 -151 -6z"
        />
        <path
          d="M7630 706 l0 -35 68 -3 67 -3 3 -152 3 -153 34 0 35 0 0 155 0 155
71 0 70 0 -3 33 -3 32 -172 3 -173 2 0 -34z"
        />
        <path
          d="M8084 457 c-3 -8 -4 -32 -2 -53 3 -38 4 -39 43 -39 l40 0 0 50 0 50
-38 3 c-25 2 -39 -1 -43 -11z"
        />
      </g>
    </svg>
  );
}
