import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

export default ({ lang }) => (
  <StaticQuery
    query={graphql`
      query helmetQuery {
        site {
          siteMetadata {
            title
            description
            keywords
            icon
          }
        }
      }
    `}
    render={data => (
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />

        <link rel="shortcut icon" type="image/png" href={data.site.siteMetadata.icon} />
        <title>{data.site.siteMetadata.title}</title>
        <html lang={lang} className="has-navbar-fixed-top" />
        {/* JIRA embedded. valamiért nem működik */}
        {/* <script data-jsd-embedded data-key="48fc03f7-5a39-4c3b-bebd-79a48bc9a978" data-base-url="https://jsd-widget.atlassian.com" src="https://jsd-widget.atlassian.com/assets/embed.js"></script> */}
      </Helmet>
    )}
  />
);
